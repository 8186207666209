import React, { useState, useEffect } from 'react';
import { FaAngleUp } from 'react-icons/fa';

import '../static/css/components/_back-to-top-btn.scss';

const BackToTopBtn = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className="top-to-btm">
      {' '}
      {showTopBtn && (
        <FaAngleUp
          className="icon-position icon-style"
          onClick={goToTop}
        />
      )}{' '}
    </div>
  );
};
export default BackToTopBtn;
